.rootJeu {
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  padding: 30px 0 50px 0;
}

.letterToFind,
.letterFind {
  font-size: 40px;
  width: 40px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
}

.endJeu {
  border: 3px solid goldenrod;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 35px;
  margin: 20px;
}

.letterToFind {
  color: wheat;
}

.letterFind {
  color: white;
}

@media all and (min-device-width: 0px) and (max-device-width: 780px) {
  .rootJeu {
    gap: 17px;
    padding: 80px 0 100px 0;
  }

  .letterToFind,
  .letterFind {
    font-size: 50px;
    width: 50px;
  }

  .endJeu {
    border: 6px solid goldenrod;
    font-size: 60px;
  }
}
