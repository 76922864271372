.rootEnd {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: -50px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.end {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 9;
  background-color: gray;
}

.screen {
  position: relative;
  z-index: 99;
  background-color: white;
  width: 550px;
  height: 350px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  text-align: center;
}

.close {
  position: absolute;
  right: 30px;
  top: 20px;
  justify-content: center;
  align-items: center;
  transition: all 500ms ease;
  display: flex;
  border-radius: 50%;
  padding: 2px;
  font-size: 25px;
}

.close:hover {
  background-color: black;
  color: wheat;
  cursor: pointer;
  transform: rotate(180deg);
}

.motTrouve {
  border: 3px solid goldenrod;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 10px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 35px;
  color: goldenrod;
}

.felications {
  color: green;
  font-size: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.perdu {
  color: red;
  font-size: 25px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.choice {
  display: flex;
  gap: 20px;
  width: 100%;
}

.secondes {
  color: orangered;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.tenta {
  color: brown;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.ratio {
  color: black;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.btn {
  background-color: royalblue;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.btn:hover {
  opacity: 0.7;
  cursor: pointer;
}

@media all and (min-device-width: 0px) and (max-device-width: 780px) {
  .btn {
    font-size: 55px;
  }

  .tenta {
    font-size: 45px;
  }

  .rootEnd {
    top: -100px;
  }

  .screen {
    z-index: 99;
    width: 650px;
    height: 650px;
    border-radius: 10px;
    gap: 0px;
  }

  .close {
    font-size: 55px;
  }

  .motTrouve {
    border: 6px solid goldenrod;
    font-size: 55px;
  }

  .felications,
  .perdu,
  .secondes {
    font-size: 55px;
  }

  .choice {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .tenta {
    font-size: 50px;
  }

  .ratio {
    font-size: 18px;
  }

  .btn {
    font-size: 40px;
  }

  .btn:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
