.globalRootLetters {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 30px 0;
  background-color: cadetblue;
}

.rootLetters {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  color: brown;
  font-family: "Fenwick Outline", sans-serif;
}

.letters {
  padding: 5px 20px;
  font-size: 65px;
  transition: all 500ms linear;
  border-radius: 10px;
}

.lettersDisabled {
  padding: 5px 20px;
  font-size: 65px;
  border-radius: 10px;
  background-color: white;
  animation: rotate 200ms linear;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.letters:hover {
  cursor: pointer;
  background-color: white;
}

@media all and (min-device-width: 0px) and (max-device-width: 780px) {
  .rootLetters {
    width: 88%;
    gap: 60px;
  }

  .letters {
    font-size: 85px;
  }

  .lettersDisabled {
    font-size: 85px;
  }
}
