.root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: cadetblue;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rootTitleReset {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btnReset {
  padding: 10px 25px;
  background-color: orangered;
  font-size: 25px;
  color: white;
  border-radius: 10px;
}

.btnReset:hover {
  cursor: pointer;
  opacity: 0.7;
}

.nbTenta {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  word-spacing: 5px;
}

.topApp {
  display: flex;
  margin-top: 5px;
  gap: 40px;
  flex-direction: column;
}

@media all and (min-device-width: 0px) and (max-device-width: 780px) {
  .btnReset {
    padding: 20px 35px;
    font-size: 45px;
  }

  .nbTenta {
    font-size: 50px;
  }

  .topApp {
    margin-top: 45px;
    gap: 100px;
  }
}
