.title {
  font-family: "SharpCurve", sans-serif;
  letter-spacing: 5px;
  color: white;
  word-spacing: 20px;
  font-size: 55px;
  text-decoration: underline;
  text-align: center;
}

@media all and (min-device-width: 0px) and (max-device-width: 780px) {
  .title {
    font-family: "SharpCurve", sans-serif;
    font-size: 80px;
  }
}
